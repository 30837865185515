import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Panko',
  defaultTitle: 'Panko',
  description: 'Trade, earn, and own crypto on the all-in-one DEX',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@Panko',
    site: '@Panko',
  },
  openGraph: {
    title: "🥞 Panko - Everyone's Favorite DEX",
    description: 'Trade, earn, and own crypto on the all-in-one DEX',
    images: [{ url: 'https://panko.finance/og/hero.png' }],
  },
}
