import { taikoHeklaTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { SerializedFarmConfig } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

// TODO: Add Mantle Chain
export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    token0: taikoHeklaTokens.test1,
    token1: taikoHeklaTokens.test2,
    lpAddress: '0xE1972Bc252A70060b884be31E5838A3611269732',
    feeAmount: FeeAmount.MEDIUM,
  },
  // {
  //   pid: 2,
  //   token0: mantleTokens.usdc, // 0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE
  //   token1: mantleTokens.wmnt, // 0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8
  //   lpAddress: '0x48AeaE837b8ebac441Ed8d00B6C5df6D3208C673',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 3,
  //   token0: new ERC20Token(
  //     ChainId.MANTLE,
  //     '0x4206A257Dbc46e4145d31eE6b64717Bd455817a7',
  //     18,
  //     'TEST 1',
  //     'PancakeSwap Token',
  //     'https://mancakeswap.finance/',
  //   ),
  //   token1: mantleTokens.wmnt,
  //   lpAddress: '0xcb9C5e852d7874Ffab7F76DB78AF2cd05aFDd647',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
])

// const farms: SerializedFarmConfig[] = [
//   /**
//    * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
//    */
//   {
//     pid: 2,
//     v1pid: 2,
//     lpSymbol: 'USDT-USDC LP',
//     lpAddress: '0xf805D18e057450182df1b877319497344586Dbc8',
//     token: mantleTokens.usdt,
//     quoteToken: mantleTokens.usdc,
//     stableSwapAddress: '0x1940Bf7e1d84A6f1f9F90A6290189601617736cb',
//     infoStableSwapAddress: '0x17287d20cFd3bC884803816684333c853F2B06ef',
//     stableLpFee: 0.0002,
//     stableLpFeeRateOfTotalFee: 0.5,
//   },
// ].map((p) => ({
//   ...p,
//   token: p.token.serialize,
//   quoteToken: p.quoteToken.serialize,
//   lpAddress: getAddress(p.lpAddress),
// }))

const farms: SerializedFarmConfig[] = []

export default farms
