import { useTheme } from '@pancakeswap/hooks'
import { useTranslation } from '@pancakeswap/localization'
import {
  Button,
  ChevronUpIcon,
  CloseIcon,
  Flex,
  FlexGap,
  Heading,
  IconButton,
  Image,
  InjectedModalProps,
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
  Spinner,
  Tag,
  Text,
} from '@pancakeswap/uikit'
import { useApplyReferralCode } from 'hooks/referral/useApplyReferralCode'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { usePasteInput } from 'hooks/usePasteInput'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useAccount } from 'wagmi'
import ConnectWalletButton from './ConnectWalletButton'

const BulletPoints = styled.ul`
  list-style-position: outside;
  padding-left: 16px;
  margin-left: 4px;
`

const BulletItem = styled.li`
  list-style-type: disc;
  padding-left: 4px;
  color: var(--colors-textSubtle, #cfaec4);
`

const ChevronIcon = styled(ChevronUpIcon)<{ expanded: boolean }>`
  transform: ${({ expanded }) => (expanded ? 'rotate(0)' : 'rotate(180deg)')};
  transition: transform 0.2s;
`

const refCodeWarnings: string[] = [
  'Only applicable for new users who have not yet staked or completed Bybit campaign.',
  'The referral code will be pending for about 10 minutes to connect to your referrer.',
  'You can stake or complete Bybit campaign after submitting the code.',
  'Please be aware that if you earn points before the referral connection is finished, your referrer will not receive the 10% reward for those points during the pending period.',
]

enum RefCodeSubmitState {
  NONE,
  SUBMITTING,
  SUCCESS,
  FAILED,
}

interface ReferralCodeModalProps extends InjectedModalProps {}

const ReferralCodeModal: React.FC<React.PropsWithChildren<ReferralCodeModalProps>> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { address } = useAccount()
  const { query, applyReferralCode } = useApplyReferralCode(address)

  const {
    Input,
    Props: { handlePaste, ref: inputRef },
    getValue,
  } = usePasteInput()
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [refCodeValidationMsg, setRefCodeValidationMsg] = useState('')
  const [expandRefNote, setExpandRefNote] = useState(true)
  const [submitState, setSubmitState] = useState<RefCodeSubmitState>(RefCodeSubmitState.NONE)
  const [failedMsg, setFailedMsg] = useState('')
  const [hideRetryBtn, setHideRetryBtn] = useState(false)

  const [storageRefCode, setStorageRefCode] = useLocalStorage('ref-code', '')
  const [refillRefCode, setRefillRefCode] = useState(true)

  const handleRevalidate = useCallback((val: string) => {
    if (!val) {
      setDisableSubmit(true)
      setRefCodeValidationMsg('Referral Code is required.')
      return
    }
    if (val.length !== 10) {
      setDisableSubmit(true)
      setRefCodeValidationMsg('Invalid Referral Code Pattern.')
      return
    }

    const regexp = new RegExp('MAN[a-zA-Z0-9]{7}')
    if (!regexp.test(val)) {
      setDisableSubmit(true)
      setRefCodeValidationMsg('Invalid Referral Code Pattern.')
      return
    }

    setDisableSubmit(false)
    setRefCodeValidationMsg('')
  }, [])

  useEffect(() => {
    if (query.isSuccess) {
      if (!query.data.allowApplyNewRefCode) {
        setSubmitState(RefCodeSubmitState.FAILED)
        setFailedMsg('You have done some tasks that cannot apply referral code.')
        setHideRetryBtn(true)
        return
      }
      switch (query.data.referralStatus) {
        case 'PENDING':
          setSubmitState(RefCodeSubmitState.SUCCESS)
          break

        case 'REJECTED':
          setSubmitState(RefCodeSubmitState.FAILED)
          setFailedMsg('Your applied code is rejected. Please try again.')
          break
        default:
          break
      }
    }
  }, [query.isSuccess, query.data?.referralStatus, query.data?.allowApplyNewRefCode])

  const fillRefCode = useCallback((): void => {
    if (!inputRef.current) {
      // retry until success
      setTimeout(fillRefCode, 500)
      return
    }
    inputRef.current.value = storageRefCode
    handleRevalidate(storageRefCode)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleRevalidate, inputRef, inputRef.current, storageRefCode])

  useEffect(() => {
    setRefillRefCode(true)
  }, [address])

  useEffect(() => {
    if (refillRefCode) {
      fillRefCode()
      setRefillRefCode(false)
    }
  }, [fillRefCode, refillRefCode])

  const handleConfirm = useCallback(async () => {
    if (!address) return
    setSubmitState(RefCodeSubmitState.SUBMITTING)
    try {
      await applyReferralCode.mutateAsync(address, getValue())
      setSubmitState(RefCodeSubmitState.SUCCESS)
      setExpandRefNote(false)
    } catch (e) {
      if (e instanceof Error) {
        if (e.name === 'UserRejectedRequestError') {
          setSubmitState(RefCodeSubmitState.NONE)
          setRefillRefCode(true)
          return
        }
        setSubmitState(RefCodeSubmitState.FAILED)
        switch (e.message) {
          case 'invalid ref code':
            setFailedMsg('Your referral code is incorrect. Please try again.')
            setExpandRefNote(false)
            break

          default:
            setFailedMsg(e.message)
            setExpandRefNote(false)
            break
        }
        return
      }

      console.error(e)
    }
  }, [address, applyReferralCode, getValue])

  const body = useMemo<React.ReactElement>(() => {
    switch (submitState) {
      case RefCodeSubmitState.NONE:
        return (
          <FlexGap flexDirection="column" gap="16px" alignItems="center">
            <FlexGap flexDirection="column" width="100%">
              <Input
                disabled={submitState !== RefCodeSubmitState.NONE}
                isWarning={!!refCodeValidationMsg}
                placeholder={t('Referral Code')}
                pattern="MAN[a-zA-Z0-9]{7}"
                onChange={(event) => {
                  const invalid = event.currentTarget.validity.patternMismatch || !event.target.value
                  setDisableSubmit(invalid)
                  if (!event.target.value) {
                    setRefCodeValidationMsg('Referral Code is required.')
                  } else if (event.currentTarget.validity.patternMismatch) {
                    setRefCodeValidationMsg('Invalid Referral Code Pattern.')
                  } else {
                    setRefCodeValidationMsg('')
                  }
                  setStorageRefCode(event.target.value)
                }}
                handlePaste={async () => {
                  const val = await handlePaste()
                  if (val !== undefined) {
                    setStorageRefCode(val)
                    handleRevalidate(val)
                  }
                  return val
                }}
                ref={inputRef}
              />
              {!!refCodeValidationMsg && (
                <Text marginTop="4px" color="warningYellow">
                  {refCodeValidationMsg}
                </Text>
              )}
            </FlexGap>
            <Button
              width="100%"
              disabled={disableSubmit || submitState !== RefCodeSubmitState.NONE}
              onClick={handleConfirm}
            >
              {submitState === RefCodeSubmitState.NONE ? t('Confirm') : t('Submitting...')}
            </Button>
          </FlexGap>
        )

      case RefCodeSubmitState.SUBMITTING:
        return (
          <FlexGap flexDirection="column" gap="16px" alignItems="center">
            <Spinner />
            <Flex flexDirection="column" alignItems="center">
              <Text fontSize="12px" textAlign="center" color="textSubtle">
                {t('Proceed in your wallet')}
              </Text>
            </Flex>
          </FlexGap>
        )

      case RefCodeSubmitState.SUCCESS:
        return (
          <FlexGap flexDirection="column" gap="16px" alignItems="center">
            <Image alt="successful-apply" src="/images/mancake-ref-apply-success.svg" width={226} height={140} />
            <FlexGap flexDirection="column" gap="8px" alignItems="center">
              <FlexGap gap="8px" alignItems="center">
                <Text bold>{t('Status')}: </Text>
                <Tag scale="alt" variant="secondary">
                  <Text fontWeight={500}>{(query.data?.referralStatus || '').toLocaleUpperCase()}</Text>
                </Tag>
              </FlexGap>
              <Flex flexDirection="column" alignItems="center">
                <Text>
                  Connecting to your referrer will take about{' '}
                  <span style={{ textDecoration: 'underline' }}>10 minutes</span>.
                </Text>
                <Text>You can check status anytime on wallet menu.</Text>
              </Flex>
            </FlexGap>
            <Button scale="md" height="40px" width="100%" onClick={onDismiss}>
              {t('Close')}
            </Button>
          </FlexGap>
        )

      case RefCodeSubmitState.FAILED:
        return (
          <FlexGap flexDirection="column" gap="16px" alignItems="center">
            <Image alt="failed-apply" src="/images/mancake-cry-with-exclamation.svg" width={226} height={140} />
            <FlexGap flexDirection="column" gap="8px" alignItems="center">
              <FlexGap gap="8px" alignItems="center">
                <Text bold>{t('Status')}: </Text>
                <Tag scale="alt" variant="secondary">
                  <Text fontWeight={500}>{'FAILED'.toLocaleUpperCase()}</Text>
                </Tag>
              </FlexGap>
              <Flex width="100%" flexDirection="column" alignItems="center">
                <Text>{failedMsg}</Text>
              </Flex>
            </FlexGap>
            {!hideRetryBtn && (
              <Button
                scale="md"
                variant="secondary"
                height="40px"
                width="100%"
                onClick={() => {
                  setSubmitState(RefCodeSubmitState.NONE)
                }}
              >
                {t('Try Again')}
              </Button>
            )}
          </FlexGap>
        )

      default:
        return <></>
    }
  }, [
    Input,
    disableSubmit,
    failedMsg,
    handleConfirm,
    handlePaste,
    handleRevalidate,
    hideRetryBtn,
    inputRef,
    onDismiss,
    query.data?.referralStatus,
    refCodeValidationMsg,
    setStorageRefCode,
    submitState,
    t,
  ])

  return (
    <ModalWrapper width={['100%', '100%', '100%', '480px']}>
      <ModalHeader background="gradientCardHeader">
        <ModalTitle>
          <Heading>{t('Enter Referral Code')}</Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={onDismiss}>
          <CloseIcon width="24px" color="text" />
        </IconButton>
      </ModalHeader>
      <ModalBody px="16px" py="24px" width="100%">
        {!address && <ConnectWalletButton />}
        {address && query.isSuccess && body}
        <Flex
          padding={12}
          borderRadius={16}
          marginTop="28px"
          alignItems="flex-start"
          justifyContent="center"
          flexDirection="column"
          background={theme.colors.background}
          flexWrap="wrap"
        >
          <Flex justifyContent="space-between" width="100%">
            <Text fontSize="16px" fontWeight={600} color="warningYellow" mb="4px">
              Referral Note
            </Text>
            <IconButton variant="text" scale="sm" onClick={() => setExpandRefNote((x) => !x)}>
              <ChevronIcon width="24px" color="#EDF06B" expanded={expandRefNote} />
            </IconButton>
          </Flex>
          {expandRefNote && (
            <BulletPoints>
              {refCodeWarnings.map((e) => {
                return (
                  <BulletItem key={e}>
                    <Text fontSize="14px" fontWeight={400} color="textSubtle" mb="4px">
                      {e}
                    </Text>
                  </BulletItem>
                )
              })}
            </BulletPoints>
          )}
        </Flex>
      </ModalBody>
    </ModalWrapper>
  )
}

export default ReferralCodeModal
