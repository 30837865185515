import { useTranslation } from '@pancakeswap/localization'
import { Flex, Image, LinkExternal } from '@pancakeswap/uikit'
import { ReactNode, memo } from 'react'
import { styled } from 'styled-components'

const Wrapper = styled.div<{ $isSide: boolean }>`
  width: 100%;
  height: ${({ $isSide }) => ($isSide ? '100%' : 'auto')};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 16px;
  padding-right: ${({ $isSide }) => ($isSide ? '32px' : '0px')};
  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: space-between;
    flex-direction: ${({ $isSide }) => ($isSide ? 'column' : 'row')};
  }
`

const BubbleWrapper = styled(Flex)`
  position: relative;
  top: 12px;
  right: 16px;

  & > svg {
    position: absolute;
    right: -15px;
    fill: ${({ theme }) => theme.colors.textSubtle};
    transition: background-color 0.2s, opacity 0.2s;
  }
  // &:hover {
  //   svg {
  //     opacity: 0.65;
  //   }
  // }
  // &:active {
  //   svg {
  //     opacity: 0.85;
  //   }
  // }

  & > a:hover {
    opacity: 1 !important;
  }

  & > a {
    border-radius: 20px;
  }
`

type FooterVariant = 'default' | 'side'

const Footer: React.FC<
  React.PropsWithChildren<{
    variant?: FooterVariant
    helpUrl?: string
    helpImage?: ReactNode
    externalText?: string
    externalLinkUrl?: string
  }>
> = ({
  variant = 'default',
  helpUrl,
  externalText,
  externalLinkUrl,
  helpImage = <Image src="/images/decorations/help-muscle-bunny.png" alt="Get some help" width={132} height={120.5} />,
}) => {
  const { t } = useTranslation()
  const isSide = variant === 'side'
  return (
    <Wrapper $isSide={isSide}>
      {externalText && externalLinkUrl && (
        <Flex flexDirection={isSide ? 'column' : ['column', 'column', 'row']} alignItems="center">
          <LinkExternal
            id="ercBridge"
            href={externalLinkUrl}
            ml={[0, 0, '40px']}
            mt={['20px', '20px', isSide ? '20px' : 0]}
            mb={['8px', '8px', 0]}
          >
            {externalText}
          </LinkExternal>
        </Flex>
      )}
      {isSide && <Flex flexGrow={1} />}
      {/* {helpUrl && (
        <Flex
          flexGrow={isSide ? 0 : 1}
          alignItems="center"
          width={["100%", "100%", "100%", isSide ? "100%" : "auto"]}
          justifyContent={["center", "center", "center", "flex-end"]}
        >
          <BubbleWrapper>
            <Button id="clickExchangeHelp" as="a" external href={helpUrl} variant="subtle">
              {t("Need help ?")}
            </Button>
            <Svg width="30" height="28" viewBox="0 0 30 28">
              <path
                d="M0 0.377058C0 0.377058 6.6857 5.16025 11.5 4.87706C20 4.37706 20.4979 4.37763 28 0.37816C35.502 -3.62131 17.002 25.3771 0 27.3771V0.377058Z"
                fill="#CFAEC4"
              />
            </Svg>
          </BubbleWrapper>
          {helpImage}
        </Flex>
      )} */}
    </Wrapper>
  )
}

export const SwapFooter = memo(Footer)
